import './scss/main.scss'
import $ from "jquery";
import popper from "popper.js";
import bootstrap from "bootstrap";
import WOW from "wow.js";
import fs from 'fs';

$(function () {
    var logo = $(".hlt-logo"); 
    $(window).scroll(function () {
        var scroll = $(window).scrollTop();
        if (scroll >= 200) {
            if (!logo.hasClass("hlt-logo-sm")) {
                logo.addClass("hlt-logo-sm")
            }
        } else {
            if (logo.hasClass("hlt-logo-sm")) {
                logo.removeClass("hlt-logo-sm")
            }
        }
    });

    $('body').scrollspy({ target: '#navbarSupportedContent' })
});


new WOW().init();